import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Carousel } from 'react-responsive-carousel'
// import "react-responsive-carousel/lib/styles/carousel.min.css";

// подвинуть карту

// шапка мобильная
// вотс и телега
// текст аккорд

import Button from '../Button'

import grass1 from '../../media/img/grass1.png'
import grass2 from '../../media/img/grass2.png'
import grass3 from '../../media/img/grass3.png'

import tg from '../../media/img/tg.png'
import wh from '../../media/img/wh.png'
import placemark from '../../media/img/placemark.png'

import contacts from '../../media/img/contacts.png'

import interior from '../../media/img/interior.png'
import interior1 from '../../media/img/interior1.png'
import interior2 from '../../media/img/interior2.png'
import interior3 from '../../media/img/interior3.png'
import interior4 from '../../media/img/interior4.png'
import interior5 from '../../media/img/interior5.png'
import interior6 from '../../media/img/interior6.png'

// import cut1 from '../../media/img/cut1.png'
import s1 from '../../media/img/1.png'
import s2 from '../../media/img/2.png'
import s3 from '../../media/img/3.png'
import s4 from '../../media/img/4.png'
import s5 from '../../media/img/5.png'
// import s6 from '../../media/img/6.png'
import s7 from '../../media/img/7.png'
import s8 from '../../media/img/8.png'
import s9 from '../../media/img/9.png'
import s10 from '../../media/img/10.png'
import s11 from '../../media/img/11.png'
import s12 from '../../media/img/12.png'

import portfolio1 from '../../media/img/portfolio1.png'
import portfolio2 from '../../media/img/portfolio2.png'

import brand1 from '../../media/img/brand1.png'
import brand2 from '../../media/img/brand2.png'
import brand3 from '../../media/img/brand3.png'
import brand4 from '../../media/img/brand4.png'
import brand5 from '../../media/img/brand5.png'

import professional from '../../media/img/professional.png'
import unique from '../../media/img/unique.png'

import certs from '../../media/img/certs.png'

import peopleGrid from '../../media/img/PeopleGrid.png'
import peopleGrid1 from '../../media/img/PeopleGrid1.png'

import followGrid from '../../media/img/followGrid.png'

import left from '../../media/svg/Left.svg'
import right from '../../media/svg/Right.svg'

import inst from '../../media/svg/inst.svg'
import vk from '../../media/svg/vk.svg'
import yt from '../../media/svg/yt.svg'

import logo from '../../media/svg/Logo.svg'


const indicatorStyles = {
    background: '#F4EFE0',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: '4px'
};

const responsive = {
    lg: {
        breakpoint: { max: 3000, min: 1280 },
        items: 1,
        slidesToSlide: 1
    },
    md: {
        breakpoint: { max: 1279, min: 900 },
        items: 1,
        slidesToSlide: 1
    },
    sm: {
        breakpoint: { max: 899, min: 580 },
        items: 1.1,
        slidesToSlide: 1
    },
    all: {
        breakpoint: { max: 579, min: 0 },
        items: 1.1,
        slidesToSlide: 1
    }
}

const cutItems = [
    { order: 0, item: 'Классическая модельная стрижка', time: '60 мин', priceM: '1800 р', priceS: '1500 р', underline: true },
    { order: 1, item: 'Стрижка машинками с переходом', time: '45 мин', priceM: '1100 р', priceS: '1000 р', underline: false },
    { order: 2, item: 'Стрижка машинками без перехода', time: '45 мин', priceM: '700 р', priceS: '500 р', underline: true }
]

const beardItems = [
    { order: 3, item: 'Традиционное влажное бритье головы/лица', time: '60 мин', priceM: '1500 р', priceS: '1200 р', underline: true },
    { order: 4, item: 'Оформление бороды', time: '45 мин', priceM: '1200 р', priceS: '1000 р', underline: false }
]

const others = [
    { order: 8, item: 'SPA процедуры для лица', time: '15 мин', priceM: '500 р', priceS: '500 р', underline: true },
    { order: 6, item: 'Тонирование бороды', time: '15 мин', priceM: '1000 р', priceS: '500 р', underline: false },
    { order: 7, item: 'Тонирование головы', time: '30 мин', priceM: ' 1500 р', priceS: '1000 р', underline: false },
    { order: 9, item: 'Укладка волос', time: '15 мин', priceM: '500 р', priceS: '500 р', underline: true },
    { order: 10, item: 'Увлажняющие патчи', time: '15 мин', priceM: '250 р', priceS: '200 р', underline: false },
    { order: 11, item: 'Удаление волос воском', time: '15 мин', priceM: '400 р', priceS: '250 р', underline: false }
]

const arrR = {
    zIndex: 1,
    top: '50%',
    // position: 'absolute',
    outline: '0',
    transition: 'all .5s',
    minWidth: '43px',
    minHeight: '43px',
    opacity: '1',
    cursor: 'pointer',
    right: 'calc(4% + 1px)',
    margin: '0 2rem 0 0'
}

const arrL = {
    zIndex: 1,
    top: '50%',
    // position: 'absolute',
    outline: '0',
    transition: 'all .5s',
    minWidth: '43px',
    minHeight: '43px',
    opacity: '1',
    cursor: 'pointer',
    left: 'calc(4% + 1px)',
    margin: '0 0 0 2rem'
}

const moscowList = [
    { dot: '#0029BB', station: 'Выставочная', adress: 'Краснопресненская набережная, д. 16, стр. 1', number: '+ 7 916 773-52-52', placemark: [55.748260, 37.543901] },
    { dot: '#00C2FF', station: 'Сокольники', adress: 'ул. Русаковская д. 22', number: '+ 7 495 773 29 29', placemark: [55.7862627, 37.676299] },
    { dot: '#AF0F0F', station: 'Молодежная', adress: 'Ярцевская улица, д.29, к 1', number: '+ 7 495 773 92 92 ', placemark: [55.742367, 37.417706] },
]

const saintpList = [
    { dot: '#AF0F0F', station: 'Лесная', adress: 'Кушелевская дорога, д.3, к.7', number: '+ 7 911 929 95 20', placemark: [59.987480, 30.371764] },
    { dot: '#AF0F0F', station: 'Академическая', adress: 'Пр-кт Науки д.12', number: '+ 7 911 920 85 15', placemark: [60.014400, 30.394073] },
    { dot: '#04BB00', station: 'Беговая', adress: 'Ул. Оптиков, д 42/24', number: '+ 7 (911) 929 01 24', placemark: [60.0004407, 30.2058813] },
    { dot: '#0029BB', station: 'Звездная', adress: 'ул. Пулковская д.19', number: '+ 7 (911) 929 95 28', placemark: [59.8349747, 30.3479833] },
    { dot: '#0029BB', station: 'Московская', adress: 'Ленинский пр-т, 159', number: '+ 7 911 920 58 99', placemark: [59.8515937, 30.3137872] },
    { dot: '#AF0F0F', station: 'Большевиков', adress: 'Коллонтай ул., 5/1', number: '+ 7 812 929 95 96', placemark: [59.9141487, 30.4472599] },
    { dot: '#FF9A02', station: 'Новочеркасская', adress: 'Заневский пр-т,  31', number: '+ 7 911 929 95 93', placemark: [59.9296477, 30.4144583] },
    { dot: '#5E00BB', station: 'Комендантский', adress: 'Уточкина ул., 1 к.1', number: '+ 7 911 929 95 97', placemark: [60.0086269, 30.2611792] },
    { dot: '#0029BB', station: 'Пионерская', adress: 'Коломяжский пр-т, 20', number: '+ 7 812 303 22 25', placemark: [59.9999331, 30.2958301] },
]

const services = [
    { img: s1, title: 'Классическая модельная стрижка', desc: 'Данная услуга выполняется с помощью ножниц и машинок, либо исключительно ножницами, если фасон стрижки удлиненный. Стрижка делается, опираясь на предпочтение клиента и профессиональный взгляд мастера. Услуга включает в себя мытьё волос до стрижки, если это требуется, непосредственно стрижку, окантовку, опаливание ушей, мытьё волос после стрижки, укладку и рекомендации от мастера по уходу за волосами и применению укладочных средств.' },
    { img: s2, title: 'Стрижка машинками с переходом', desc: 'Данная стрижка выполняется исключительно машинками с применением насадок и включает в себя переход от меньшей длины волос к большей, что в результате дает эффект плавности перехода. Услуга включает в себя мытьё волос до стрижки, если это требуется, непосредственно стрижку, окантовку, опаливание ушей, мытьё волос после стрижки и рекомендации от мастера по уходу за волосами' },
    { img: s3, title: 'Стрижка машинками без перехода', desc: 'Стрижка выполняется исключительно машинкой с насадкой одной длины по всей площади головы. Услуга включает в себя мытьё волос до стрижки, если это требуется, непосредственно стрижку, окантовку, опаливание ушей, мытьё волос после стрижки и рекомендации от мастера по уходу за волосами' },
    { img: s4, title: 'Традиционное влажное салонное бритье лица/головы', desc: 'Традиционное влажное бритьё выполняется опасной бритвой со сменным лезвием. Услуга включает в себя подготовку к бритью путём распаривания горячими полотенцами, непосредственно бритьё, холодный компресс, опаливание ушей и косметический уход после бритья' },
    { img: s5, title: 'Оформление бороды', desc: 'Оформление бороды выполняется с помощью машинки, тримера и опасной бритвы со сменным лезвием. Данная услуга включает в себя коррекцию длинны бороды и усов, окантовку по линии роста волос на шее и щеках. А также распаривание горячими полотенцами, бритьё опасной бритвой со сменным лезвием, холодный компресс и косметический уход после бритья' },
    { img: s5, title: 'Починить', desc: 'Починить' },
    { img: s7, title: 'Тонированние бороды', desc: 'Тонировка выполнять совместно с оформлением бороды. С помощью тонировки наши мастера придадут насыщенный натуральный оттенок вашей бороде. Окрашивание помогает добиться равномерного тона по всей длине и замаскировать седину' },
    { img: s8, title: 'Тонироавние головы камуфляж седины', desc: 'Тонирование выполняется совместно со стрижкой.  Это услуга маскирует седину и придает натурально насыщенный цвет волосам. Цветовая палитра красок для камуфляжа включает в себя только естественные оттенки' },
    { img: s9, title: 'SPA процедура для лица', desc: 'Это комплексный уход за кожей лица.  В процедуру входит распаривание горячими компрессами, очищение кожи лица с помощью скраба и успокаивающая маска. Рекомендуемая частота процедуры: 1 раз в 2 недели' },
    { img: s12, title: 'Укладка волос', desc: 'Данная услуга будет актуальна для Вас в случае важного мероприятия или при необходимости быстро придать стрижке свежий и ухоженный внешний вид. В услугу входит: мытье головы, сушка феном, укладка с помощью профессиональных средств по уходу' },
    { img: s11, title: 'Увлажняющие патчи для глаз', desc: 'Косметические гелевые накладки, визуально похожие на пластырь, устраняют отечность, покраснения и сухость. Минимизируют круги под глазами. Рекомендуем наносить во время стрижки' },
    { img: s10, title: 'Удаление волос воском', desc: 'Процедура, с помощью которой можно удалить нежелательные волосы в области носа, ушей, а также межбровной части. Рекомендуемая частота процедуры: 1 раз в 2 недели' },
]



const Header = (props) => {
    const { title, subTitle } = props
    return (
        <div className='font-extrabold'>
            <div className='text-3xl md:text-4xl'>{title}</div>
            <div className='text-xl'><em>{subTitle}</em></div>
        </div>
    )
}

const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
        <li
            className={active ? "carouselDot--active" : "carouselDot"}
            onClick={() => onClick()}
        >
            <button></button>
        </li>
    );
};

const Benefits = () => {
    return (
        <div className='flex flex-col md:flex-row justify-between font-bold mb-20 text-lg'>
            <div className='my-2'>
                <img className='h-16 w-auto my-4' src={grass1} alt="Собственная линия косметики" />
                <div className=''>Собственная линия <br /> косметики</div>
            </div>

            <div className='my-4'>
                <img className='h-16 w-auto my-4' src={grass2} alt="Барбершопов в Москве и Санкт-Петербурге" />
                <div className=''>Барбершопов в Москве <br /> и Санкт-Петербурге</div>
            </div>

            <div className='my-4'>
                <img className='h-16 w-auto my-4' src={grass3} alt="Школа обучения барберов" />
                <div className=''>Школа обучения <br /> барберов</div>
            </div>
        </div>
    )
}

const Philosophy = () => {
    const [portfolioSlider, setPortfolioSlider] = useState(0)

    const updateCurrentSlide = (index) => {
        if (portfolioSlider !== index) {
            setPortfolioSlider(index)
        }
    }

    return (
        <div id='history' className='flex flex-col lg:flex-row justify-between mb-20'>
            <div className='font-bold lg:w-1/2'>
                <Header title='Философия' subTitle='Забота о клиентах — главная задача всей команды!' />
                {/* <div className='text-lg mt-8 -ml-4'><em>«</em> CHAIN Barberrshop – это Ваша дорогая привычка <em>»</em></div> */}
                <div className='text-lg mt-4 font-normal'>
                    <b className='font-bold'>CHAIN</b>  — сеть мужских парикмахерских. Начав свой путь в 2014 году, сегодня сеть CHAIN Barbershop насчитывает 12 Барбершопов в Санкт-Петербурге и Москве!
                </div>

                <div className='text-lg mt-4 font-normal'>
                    <b className='font-bold'>Миссия CHAIN Barbershop: </b>
                    Предоставление качественных услуг, опираясь на единый регламент работы и
                    оказывая первоклассный сервис с уважением к каждому клиенту.
                </div>
            </div>
            <div className='flex lg:w-1/2 h-full'>
                <button type="button" className='hidden md:block' onClick={() => setPortfolioSlider(portfolioSlider - 1)} style={{ ...arrL, paddingRight: '10px' }}>
                    <img src={left} alt="" />
                </button>
                <div className='mt-10 md:mt-0'>
                    <Carousel
                        keyBoardControl={true}
                        responsive={responsive}
                        showThumbs={false}
                        showArrows={false}
                        showStatus={false}
                        swipeable={true}
                        emulateTouch={true}
                        // customDot={<CustomDot />}
                        // showIndicators={false}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (
                                    <li
                                        style={{ ...indicatorStyles, background: '#C2AB6D' }}
                                        aria-label={`Selected: ${label} ${index + 1}`}
                                        title={`Selected: ${label} ${index + 1}`}
                                    />
                                );
                            }
                            return (
                                <li
                                    style={indicatorStyles}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    title={`${label} ${index + 1}`}
                                    aria-label={`${label} ${index + 1}`}
                                />
                            );
                        }}
                        selectedItem={portfolioSlider}
                        onChange={updateCurrentSlide}
                    >
                        <img className='pointer-events-none' src={interior5} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior1} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior2} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior3} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior4} alt="" srcset="" />
                        <img className='pointer-events-none' src={interior6} alt="" srcset="" />
                    </Carousel>
                </div>
                <button type="button" className='hidden md:block' onClick={() => setPortfolioSlider(portfolioSlider + 1)} style={{ ...arrR, paddingLeft: '10px' }}>
                    <img src={right} alt="" />
                </button>
            </div>
        </div>
    )
}

const Portfolio = () => {
    const [portfolioSlider, setPortfolioSlider] = useState(0)

    const updateCurrentSlide = (index) => {
        if (portfolioSlider !== index) {
            setPortfolioSlider(index)
        }
    }
    return (
        <div >
            <div id='portfolio' className='container mx-auto mb-8'>
                <Header title='Наши работы' subTitle='CHAIN Your Life' />
                <div className='text-xl font-bold mt-2'>Профессиональный подход к каждому клиенту</div>
                <div className='text-xl font-bold mt-8'><em>«</em>Chain — your personal Barber<em>»</em></div>
            </div>
            <div style={{
                background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(194,171,109,1) 80%)',
                padding: '1rem'
            }} className='flex mt-10'>
                <button type="button" className='hidden md:block' onClick={() => setPortfolioSlider(portfolioSlider - 1)} style={{ ...arrL }}>
                    <img src={left} alt="" />
                </button>
                <div className='md:w-1/2 w-full mx-auto'>
                    <Carousel
                        keyBoardControl={true}
                        responsive={responsive}
                        showThumbs={false}
                        showArrows={false}
                        showStatus={false}
                        swipeable={true}
                        emulateTouch={true}
                        // showIndicators={false}
                        customDot={<CustomDot />}
                        selectedItem={portfolioSlider}
                        onChange={updateCurrentSlide}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (
                                    <li
                                        style={{ ...indicatorStyles, background: '#C2AB6D' }}
                                        aria-label={`Selected: ${label} ${index + 1}`}
                                        title={`Selected: ${label} ${index + 1}`}
                                    />
                                );
                            }
                            return (
                                <li
                                    style={indicatorStyles}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    title={`${label} ${index + 1}`}
                                    aria-label={`${label} ${index + 1}`}
                                />
                            );
                        }}
                    >
                        <div className='bg-transparent'><img src={portfolio1} alt="" /></div>
                        <div className='bg-transparent'><img src={portfolio2} alt="" /></div>
                    </Carousel>
                </div>
                <button type="button" className='hidden md:block' onClick={() => setPortfolioSlider(portfolioSlider + 1)} style={{ ...arrR }}>
                    <img src={right} alt="" />
                </button>
            </div>
            <div className='flex flex-wrap justify-center mb-20 mt-40'>
                <img src={brand1} className='mr-2' alt="brand1" />
                <img src={brand2} className='mx-2' alt="brand2" />
                <img src={brand3} className='mx-2' alt="brand3" />
                <img src={brand4} className='mx-2' alt="brand4" />
                <img src={brand5} className='ml-2' alt="brand5" />
            </div>
        </div>
    )
}

const Brands = () => {
    return (
        <div id='cosmetics' className='mx-auto lg:mr-0 '>

            <div className='flex justify-end lg:flex-row flex-col mb-10'>
                <div className='lg:w-5/12'>
                    <div className='mb-12 md:mb-8 ml-0 lg:ml-40'>
                        <Header title='Профессиональная косметика' subTitle='The best service money can buy' />
                    </div>
                    <div className='text-xl ml-0 lg:ml-40'>Мы отобрали лучшие средства для бритья, укладки и ухода за волосами.
                    За время работы протестировали более 25 различных брендов из Штатов,
                    Великобритании и Нидерландов. Обратитесь к администратору в любом из
                    салонов сети CHAIN для более подробной консультации.
                        </div>
                </div>
                <img className='mb-8 md:mb-0 w-full lg:w-1/2 h-full' src={professional} alt="professional" />
            </div>
            <div className='flex justify-end lg:flex-row flex-col mb-10'>
                <div className='lg:w-5/12'>
                    <div className='mb-12 md:mb-8 ml-0 lg:ml-40'>
                        <Header title='Уникальная косметика CHAIN' subTitle='Мы чтим традиции нашего бренда!' />
                    </div>
                    <div className='text-xl ml-0 lg:ml-40'>Собственный бренд косметики CHAIN — результат долгой работы всей команды. Отечественный продукт, созданный нашими технологами, с учетом
                    шестилетнего опыта. Также в продаже во всей сети CHAIN Barbershop.
                        </div>
                </div>
                <img className='mb-8 md:mb-0 w-full lg:w-1/2 h-full' src={unique} alt="professional" />
            </div>
        </div>
    )
}


const Contacts = () => {
    return (
        <div id='contacts' className='mt-40 flex flex-wrap lg:flex-no-wrap justify-between'>
            <div className='lg-w41'>
                <div className='font-bold text-2xl mb-4'>Контакт-центр</div>
                <div>
                    <div className='mb-6'>Контакт-центр CHAIN Barbershop ответит на все Ваши вопросы,
                    отзывы и замечания! Для нас Ваша обратная связь является фундаментом
                    для развития. Мы всегда открыты для наших клиентов! Мы делаем
                    все, чтобы быть лучше для Вас!
                </div>
                    <div className='font-extrabold text-xl flex'>
                        <a style={{ textDecorationColor: '#C2AB6D' }} className='underline' href="tel:+7 991 027-49-88">+7 991 027-49-88</a>
                        <a href="https://t.me/chain_callcenter" target="_blank" rel="noopener noreferrer">
                            <img className='mx-4' src={tg} alt="tg" />
                        </a>
                        <a href="https://wa.me/79910274988" target="_blank" rel="noopener noreferrer">
                            <img src={wh} alt="wh" />
                        </a>
                    </div>
                </div>
            </div>
            <img className='lg:h-full mt-4 w-1/2 md:w-1/3' style={{ height: '100%'}} src={contacts} alt="contacts" />
        </div>
    )
}

const ContactSchool = () => {
    return (
        <div className='mt-40 flex flex-wrap lg:flex-no-wrap justify-between'>
            <div className='lg-w41'>
                <div className='font-bold text-2xl mb-4'>Обсудите с нами программу обучения</div>
                <div>
                    <div className='mb-6'>CHAIN School ответит на все Ваши вопросы, связанные с обучением, предоставит консультацию и подберет оптимальный вариант обучения</div>
                    
                </div>
            </div>
            <div className='lg:h-full mt-4 w-1/2 md:w-1/3'  style={{ height: '100%'}} alt="contacts" />
        </div>
    )
}

const Gift = () => {
    const [active, setActive] = useState(0)
    return (
        <div className='mt-40 flex flex-wrap lg:flex-no-wrap justify-between'>
            <div className='lg-w41'>
                <div className='font-bold text-2xl mb-6'>Подарочный сертификат CHAIN</div>
                <div>
                    <div className='mb-10 text-md'>
                        Подарок для мужчины у которого есть все! Действует во
                        всей сети CHAIN на услуги и косметику для ухода!
                </div>
                    <div className='font-bold text-md '>
                        Номиналы сертификатов
                    <div className='flex text-md mt-6'>
                            <div onMouseEnter={() => setActive(0)} className={`-ml-1 mr-4 px-3 ${active === 0 ? 'bg-gold-200  rounded' : ''}`}>2000</div>
                            <div onMouseEnter={() => setActive(1)} className={`mx-4 px-3 ${active === 1 ? 'bg-gold-200  rounded' : ''}`}>3000</div>
                            <div onMouseEnter={() => setActive(2)} className={`mx-4 px-3 ${active === 2 ? 'bg-gold-200  rounded' : ''}`}>5000</div>
                            <div onMouseEnter={() => setActive(3)} className={`mx-4 px-2 ${active === 3 ? 'bg-gold-200  rounded' : ''}`}>10000</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='lg:h-full mt-4 w-1/2 md:w-1/3' style={{ height: '30%' }} src={certs} alt="cert" />
        </div>
    )
}

const People = () => {
    return (
        <div id='team' className='mt-40'>
            <Header title='Наша команда' subTitle='CHAIN Your Life' />
            <div>
                CHAIN — это самый большой в России коллектив
                профессионалов индустрии барберинга.
            </div>
            <div className='text-lg font-bold my-8'><em>«</em> CHAIN — your personal Barber <em>»</em></div>
            <div className='w-full flex justify-center'>
                <img style={{height:'100%' }} className='mr-2 w-1/2 inline' src={peopleGrid} alt="" />
                <img style={{ width: '44%', height:'100%' }} className='inline' src={peopleGrid1} alt="" />
            </div>
        </div>
    )
}

const Follow = () => {
    return (
        <div id="social" className='mt-40 flex flex-wrap lg:flex-no-wrap justify-center'>
            <div className='lg-w41'>
                <div className='text-2xl font-bold'>Следите за нами</div>
                <div className='flex my-4'>
                    <a href="https://www.instagram.com/chain_barbershop/" target="_blank" rel="noopener noreferrer">
                        <img className='mr-4' src={inst} alt="instagram" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC7ja0W-aoZMOzMKNIjvB5RQ">
                        <img className='mr-4' src={yt} alt="youtube" target="_blank" rel="noopener noreferrer" />
                    </a>
                    <a href="https://vk.com/chain_barbershop">
                        <img className='' src={vk} alt="vkontakte" target="_blank" rel="noopener noreferrer" />
                    </a>
                </div>
                <div className='mt-8 mb-4'>
                    В наших соцсетях вы всегда увидите актуальные новости
                    из жизни нашего комьюнити и найдете для себя свежие идеи.
                </div>
            </div>
            <img className='lg:w-2/5 lg:ml-20 w-full' style={{height:'100%' }} src={followGrid} alt="follow" />
        </div>
    )
}

const School = () => {
    return (
        <div id='school' className='mt-40'>
            <div className='font-extrabold text-3xl'>CHAIN School, школа обучения барберов!</div>
            <div className='font-bold'><em>Мы верим в регламент нашей работы!</em></div>
            <div className='mt-10'>95% всех курсов — практика! На протяжении всего
            обучения Вы работаете на моделях. Оборудование,
            инструменты, расходные материалы, косметика, манекены и
            модели предоставляются бесплатно на период обучения. По
            окончании курса вручается именной диплом и подарок от школы,
            а также все фотоматериалы, снятые в процессе обучения.
            </div>
            <div className='font-bold text-2xl mt-10'>Мы гарантируем</div>
            <div className='hidden lg:flex mt-4 '>
                <div className='bg-gold-200 rounded mr-5 w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Индивидуальный подход</div>
                    <div className=''>Эффективнее на 40% чем массовые семинары и занятия в группах</div>
                </div>
                <div className='bg-gold-200 rounded mx-5 w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Доступность материала</div>
                    <div className=''>Максимально упрощенная терминология и визуальное представление материала</div>
                </div>
                <div className='bg-gold-200 rounded mx-5  w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Постоянная практика</div>
                    <div className=''>Все преподаватели практикующие мастера Chain Barbershop</div>
                </div>
                <div className='bg-gold-200 rounded ml-5 w-1/4 p-3'>
                    <div className='font-bold text-lg  mb-3'>Культура профессии</div>
                    <div className=''>Ученики попадают в  атмосферу работы барбершопа, погружаются в профессию барбера</div>
                </div>
            </div>
            <div className='md:flex flex-wrap lg:hidden mt-4 block'>
                <div style={{ flexGrow: 0, flexShrink: '0', flexBasis: '48%' }} className='w-full bg-gold-200 rounded md:mr-2 mt-4 md:mt-0 md:w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Индивидуальный подход</div>
                    <div className=''>Эффективнее на 40% чем массовые семинары и занятия в группах</div>
                </div>
                <div style={{ flexGrow: 0, flexShrink: '0', flexBasis: '48%' }} className='w-full bg-gold-200 rounded md:ml-2 mt-4 md:mt-0 md:w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Достпупность материала</div>
                    <div className=''>Максимально упрощенная терминология и визуальное представление материала</div>
                </div>
                <div style={{ flexGrow: 0, flexShrink: '0', flexBasis: '48%' }} className='w-full bg-gold-200 rounded md:mr-2 mt-4  md:w-1/4 p-3'>
                    <div className='font-bold text-lg mb-3'>Постоянная практика</div>
                    <div className=''>Все преподаватели практикующие мастера Chain Barbershop</div>
                </div>
                <div style={{ flexGrow: 0, flexShrink: '0', flexBasis: '48%' }} className='w-full bg-gold-200 rounded md:ml-2 mt-4 md:w-1/4 p-3'>
                    <div className='font-bold text-lg  mb-3'>Культура профессии</div>
                    <div className=''>Ученики попадают в  атмосферу работы барбершопа, погружаются в профессию барбера</div>
                </div>
            </div>

        </div>
    )
}

const Accordion = () => {
    return (
        <div class="accordion hidden lg:block">
            <ul>
                <li styles={{ zIndex: 1 }} tabindex="1">
                    <div>
                        <span style={{ width: '95vw' }} className='flex' href="#">
                            <div className='p-3'>
                                <div className='font-extrabold text-3xl'><em>«Барбер с нуля»</em></div>
                                <div className='mt-20'>
                                    • Срок обучения: 15 дней <br />
                                    • Количество академических часов: 80 <br />
                                    • Количество учеников: 1 <br />
                                    • Стоимость курса: 65 000 рублей <br />
                                    {/* • Программа курса: <br /> */}
                                </div>
                            </div>

                            <div className='p-3'>
                                <h2 className='font-extrabold'>I. Теоретический блок</h2>
                                <ol className='mt-8'>
                                    <li>1.Культура мужской парикмахерской</li>
                                    <li>2.Специфика работы</li>
                                    <li>3.Инструменты</li>
                                    <li>4.Оборудование</li>
                                    <li>5.Косметические бренды</li>
                                    <li>6.Стандарты обслуживания</li>
                                    <li>7.Психологические секреты работы с клиентом</li>
                                </ol>
                            </div>

                            <div className='p-3'>
                                <h2 className='font-extrabold'>II. Практический блок</h2>
                                <ol className='mt-8'>
                                    <ol>
                                        <li>1. Стрижка Цезарь одной насадка до 9 мм.</li>
                                        <li>2. Стрижка Цезарь с переходом до 9 мм.</li>
                                        <li>3. Стрижка машинкой до 25 мм.</li>
                                        <li>4. Использование ножниц в процессе выполнения различных видов стрижек.</li>
                                        <li>5. Традиционное влажное бритье головы.</li>
                                        <li>6. Традиционное влажное бритье лица.</li>
                                        <li>7. Оформление и моделирование бороды.</li>
                                        <li>8. Дополнительный уход за кожей и волосом.</li>
                                        <li>9. Мытье волос с элементами массажа головы.</li>
                                        <li>10. Сушка и укладка.</li>
                                    </ol>
                                </ol>
                            </div>

                            <div className='p-3'>
                                {/* <h2 className='font-extrabold'>III. Экзамен</h2>
                                <ol className='mt-8'>
                                    <li>1.Культура мужской парикмахерской</li>
                                    <li>2.Специфика работы</li>
                                    <li>3.Инструменты</li>
                                    <li>4.Оборудование</li>
                                    <li>5.Косметические бренды</li>
                                    <li>6.Стандарты обслуживания</li>
                                    <li>7.Психологические секреты работы с клиентом</li>
                                </ol> */}
                            </div>

                        </span>

                    </div>
                </li>
                <li style={{ zIndex: 2 }} tabindex="2">
                    <div>
                        <span style={{ width: '95vw' }} className='flex' href="#">
                            <div className='p-3'>
                                <div className='font-extrabold text-3xl'>Повышение квалификации</div>
                                <div className='mt-20'>
                                    • Срок обучения: 3 дня <br />
                                    • Количество академических часов: 16 <br />
                                    • Количество учеников: 1 <br />
                                    • Стоимость курса: 26 000 рублей <br />
                                    {/* • Программа курса: <br /> */}
                                </div>
                            </div>

                            <div className='p-3'>
                                <h2 className='font-extrabold'>Теория и практика</h2>
                                <ol className='mt-8'>
                                    <li>1. Постановка тела и рук.</li>
                                    <li>2. Хваты и использование инструмента.</li>
                                    <li>3. Обзор косметических брендов и правила использования.</li>
                                    <li>4. Технологический процесс и поэтапное выполнение работ.</li>
                                    <li>5. Стрижка машинкой до 25 мм.</li>
                                    <li>6. Классическая модельная стрижка.</li>
                                    <li>7. Fade.</li>
                                    <li>8. Контурная окантовка.</li>
                                </ol>
                            </div>
                            <div className='p-3'>
                                <ol className='mt-8'>
                                    <li>9. Оформление и моделирование бороды.</li>
                                    <li>10. Дополнительный уход за кожей и волосом.</li>
                                    <li>11. Мытье волос с элементами массажа головы.</li>
                                    <li>12. Сушка и укладка.</li>
                                    <li>13. Секреты быстрой и эффективной работы.</li>
                                    <li>14. Психологические приемы в работе.</li>
                                    <li>15. Устранение ошибок работы.</li>
                                </ol>
                            </div>

                            <div className='p-3'>
                                <ol className='mt-8'>

                                </ol>
                            </div>

                        </span>
                    </div>
                </li>
                <li style={{ zIndex: 3 }} tabindex="3">
                    <div>
                        <span style={{ width: '95vw' }} className='flex' href="#">
                            <div className='p-3'>
                                <div className='font-extrabold text-3xl'>«Традиционное влажное<br /> бритье  и оформление <br /> бороды»</div>
                                <div className='p-3'>
                                    • Срок обучения: 1 день <br />
                                    • Количество академических часов: 4 <br />
                                    • Количество учеников: 1 <br />
                                    • Стоимость курса: 9 000 рублей <br />
                                    {/* • Программа курса: <br /> */}
                                </div>
                            </div>
                            <div className='p-3'>
                                <div className='mt-20'>
                                    <ol>
                                        <li>1. Технология влажного бритья.</li>
                                        <li>2. Традиционное влажное бритье головы.</li>
                                        <li>3. Традиционное влажное бритье лица.</li>
                                        <li>4. Оформление и моделирование бороды.</li>
                                        <li>5. Дополнительный уход за кожей лица и волосом.</li>
                                        <li>6. Тонирование бороды. </li>
                                    </ol>
                                </div>
                            </div>
                            <div className='p-3'>
                            </div>

                            <div className='p-3'>
                            </div>
                        </span>
                    </div>
                </li>
                <li style={{ zIndex: 4 }} tabindex="4">
                    <div>
                        <span style={{ width: '95vw' }} className='flex' href="#">
                            <div className='p-3'>
                                <div className='font-extrabold text-3xl'>«Технология стрижки fade»</div>
                                <div>
                                    • Срок обучения: 1 день <br />
                                    • Количество академических часов: 4 <br />
                                    • Количество учеников: 1 <br />
                                    • Стоимость курса: 9 000 рублей <br />
                                    {/* • Программа курса: <br /> */}
                                </div>
                            </div>
                            <div className='p-3'>
                                <div className='mt-20'>
                                    1. Техника выполнения Fade. <br />
                                    2. Lowfade. <br />
                                    3. Midfade. <br />
                                    4. Highfade. <br />
                                    5. Работа бритвой на окантовках. <br />
                                </div>
                            </div>

                            <div className='p-3'>
                            </div>

                            <div className='p-2'>
                            </div>

                        </span>
                    </div>
                </li>

            </ul>
        </div>
    )
}

const Footer = () => {
    return (
        <div style={{ backgroundColor: '#444444' }} className='mt-40 h-auto'>
            <div className='container mx-auto pt-4'>
                <div className='flex flex-col lg:flex-row justify-between text-white'>
                    <div className='flex flex-col md:flex-row'>
                        <img className='md:w-auto h-16 pt-3' src={logo} alt="CHAIN" />
                        <div className='ml-0 md:ml-12'>
                            <div className='flex flex-col lg:flex-row font-bold'>
                                <Link style={{width: 'max-content'}} className='hover:text-gold-500 mr-4' smooth to="#services">Услуги</Link>
                                <Link style={{width: 'max-content'}} className='hover:text-gold-500 mr-4' smooth to="#portfolio">Наши работы</Link>
                                <Link style={{width: 'max-content'}} className='hover:text-gold-500 mr-4' smooth to="#team">Наша команда</Link>
                                <Link style={{width: 'max-content'}} className='hover:text-gold-500 mr-4' smooth to="#school">Школа</Link>
                            </div>
                            <div>
                                <div className='flex mt-4'>
                                    <div>Контакт центр</div>
                                    <div className='ml-auto pl-4 md:mr-40 mr-20'>
                                        <em>
                                            +7 991 027 49 88
                                    </em>
                                    </div>
                                </div>
                                <div className='flex mt-2'>
                                    <div>Школа</div>
                                    <div className='ml-auto pl-4 md:mr-40 mr-20'>
                                        <em>
                                            +7 921 366 50 12
                                    </em>
                                    </div>
                                </div>
                                <div className='flex mt-2 mb-8'>
                                    <div>Почта</div>
                                    <div className='ml-auto pl-4 md:mr-40 mr-20'>
                                        <em>
                                            info@chainbarbershop.ru
                                    </em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex mb-4 mt-4 md:mt-0'>
                            <a href="https://t.me/chain_callcenter" target="_blank" rel="noopener noreferrer">
                                <img className='mr-4' src={tg} alt="tg" />
                            </a>
                            <a href="https://wa.me/79910274988" target="_blank" rel="noopener noreferrer">
                                <img className='mr-4' src={wh} alt="wh" />
                            </a>
                            <a href="https://www.instagram.com/chain_barbershop/" target="_blank" rel="noopener noreferrer">
                                <img className='mr-4' src={inst} alt="instagram" />
                            </a>
                            <a href="https://www.youtube.com/channel/UC7ja0W-aoZMOzMKNIjvB5RQ">
                                <img className='mr-4' src={yt} alt="youtube" target="_blank" rel="noopener noreferrer" />
                            </a>
                            <a href="https://vk.com/chain_barbershop">
                                <img className='' src={vk} alt="vkontakte" target="_blank" rel="noopener noreferrer" />
                            </a>
                        </div>
                        <Button isCall isFilled additional='hover:text-black' >записаться онлайн</Button>

                    </div>
                </div>
            </div>
        </div>
    )
}

const Main = () => {
    const moscow = [55.751574, 37.573856]
    const saintp = [59.9342802, 30.3350986]
    const [center, setCenter] = useState(moscow)
    const [zoom, setZoom] = useState(10)
    const [city, setCity] = useState('moscow')

    const Services = () => {
        const [info, setInfo] = useState(0)
        const [currentItem, setCurrentItem] = useState('Классическая модельная стрижка')

        const ServicesItem = (props) => {
            const { item, time, price, underline, order } = props
            return (
                <div onMouseEnter={() => { setInfo(order); setCurrentItem(item) }} onClick={() => { setInfo(order); setCurrentItem(item) }} className='mb-4'>
                    <div className={`text-lg font-semibold rounded hover:bg-gold-200 px-3 ${currentItem === item ? 'bg-gold-200' : ''}`}>{item}</div>
                    <div className='text-base font-normal mx-3'>{time} /<b className='font-bold'> {price}</b></div>
                    <div className={underline ? 'hidden md:block border border-t border-gold-500' : ''}></div>
                </div>
            )
        }

        return (
            <div id='services' className='mb-20'>
                <div className='flex justify-between mb-20'>
                    <Header title='Услуги' subTitle='Все дело в деталях!' />
                    <div className='text-md flex bg-white mt-12 flex-wrap'>
                        <div className={`px-2 py-1 font-bold cursor-pointer mr-8 ${arrayEquals(center, moscow) ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => setCenter(moscow)}>Москва</div>
                        <div className={`px-2 py-1 font-bold cursor-pointer ${arrayEquals(center, saintp) ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => setCenter(saintp)}>Санкт-Петербург</div>
                    </div>
                </div>

                <div className='mt-4 flex flex-col lg:flex-row justify-between lg:mr-16' >
                    <div className='flex w-full lg:w-3/4'>
                        <div className='text-xl font-bold mr-0 md:mr-4'>

                            <div className='mx-3 font-extrabold'>Стрижка</div>
                            {cutItems.map(el => <ServicesItem key={el.order} item={el.item} time={el.time} price={arrayEquals(center, saintp) ? el.priceS : el.priceM} underline={el.underline} order={el.order} />)}

                            <div className='mx-3 font-extrabold'>Бритье и борода</div>
                            {beardItems.map(el => <ServicesItem key={el.order} item={el.item} time={el.time} price={arrayEquals(center, saintp) ? el.priceS : el.priceM} underline={el.underline} order={el.order} />)}


                        </div>
                        <div className='text-xl font-extrabold mx-auto'>
                            <div className='mx-3'>Дополнительно</div>
                            {others.map((el) => <ServicesItem key={el.order} item={el.item} time={el.time} price={arrayEquals(center, saintp) ? el.priceS : el.priceM} underline={el.underline} order={el.order} />)}
                        </div>
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <img styles={{ marginLeft: '-3rem' }} src={services[info].img} alt="cut1" />
                        <div className='text-lg font-bold my-3'>{services[info].title}</div>
                        <div className='text-base font-normal'>
                            {services[info].desc}
                        </div>
                    </div>
                </div>
                <div className='mt-8 '>
                    <Button isCall isFilled additional='mr-4 hover:text-black' >записаться онлайн</Button>
                </div>
            </div>
        )
    }

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const Addreses = () => {

        if (city === 'saint') {
            return (saintpList.map((el, index) => {
                return (
                    <div  onClick={() => { setCenter(el.placemark); setZoom(15) }} className='mb-2' key={`spb${index}`}>
                        <span style={{ color: el.dot }} className='text-3xl align-middle leading-3 inline -ml-2'>•</span>
                        <div className='inline font-bold text-xl ml-2'>{el.station}</div>
                        <div className='text-md ml-2'>{el.adress}</div>
                        <a className='font-bold text-md -ml-2' href={`tel:${el.number}`}>{el.number}</a>
                        <div className='border border-t border-gold-500'></div>
                    </div>

                )
            }))
        } else {
            return (moscowList.map((el, index) => {
                return (
                    <div  onClick={() => { setCenter(el.placemark); setZoom(15) }} className='mb-2' key={`mow${index}`}>
                        <span style={{ color: el.dot }} className='text-3xl align-middle leading-3 inline -ml-2'>•</span>
                        <div className='inline font-bold text-xl ml-2'>{el.station}</div>
                        <div className='text-md'>{el.adress}</div>
                        <a className='font-bold text-md -ml-2' href={`tel:${el.number}`}>{el.number}</a>
                        <div className='border border-t border-gold-500'></div>
                    </div>
                )
            }))
        }
    }

    const AddresesM = () => {

        if (city === 'saint') {
            return (saintpList.map((el, index) => {
                return (
                    <div onClick={() => { setCenter(el.placemark); setZoom(15) }} className='mb-2 mt-2 block lg:hidden' key={`spb${index}`}>
                        <span style={{ color: el.dot }} className='text-3xl align-middle leading-3 inline -ml-2'>•</span>
                        <div className='inline font-bold text-xl ml-2'>{el.station}</div>
                        <div className='text-md'>{el.adress}</div>
                        <a className='font-bold text-md -ml-2' href={`tel:${el.number}`}>{el.number}</a>
                        <div className='border border-t border-gold-500'></div>
                    </div>


                )
            }))
        } else {
            return (moscowList.map((el, index) => {
                return (
                    <div onClick={() => { setCenter(el.placemark); setZoom(15) }} className='mb-2 mt-2 block lg:hidden' key={`mow${index}`}>
                        <span style={{ color: el.dot }} className='text-3xl align-middle leading-3 inline -ml-2'>•</span>
                        <div className='inline font-bold text-xl ml-2'>{el.station}</div>
                        <div className='text-md'>{el.adress}</div>
                        <a className='font-bold text-md -ml-2' href={`tel:${el.number}`}>{el.number}</a>
                        <div className='border border-t border-gold-500'></div>
                    </div>
                )
            }))
        }
    }

    const PlacemarksList = () => {
        saintpList.concat(moscowList)

        return (saintpList.concat(moscowList).map((el, index) => {
            if (el.placemark.length) {
                return (
                    <Placemark key={`pm${index}`} onClick={() => { setCenter(el.placemark); setZoom(15) }} geometry={el.placemark} options={{
                        iconLayout: 'default#image',
                        iconImageHref: placemark,
                        iconImageSize: [40, 40],
                        iconImageOffset: [-20, -20],
                        // Defining a hotspot on top of the image.
                        iconShape: {
                            type: 'Circle',
                            coordinates: [0, 0],
                            radius: 20
                        },
                    }}
                    />
                )
            }else return null
        }))
    }

    const YandexMapComponent = () => {
        return (
            <div>
                <div id='map' style={{ height: '560px' }}>
                    <YMaps >
                        <div className='container mx-auto text-4xl font-bold mb-4'>Наши адреса</div>
                        <div className='relative'>

                            <Map width='100%' height='500px' state={{ center: center, zoom: zoom }} defaultState={{ center: moscow, zoom: 9 }} >
                                <PlacemarksList />
                            </Map>
                            <div className=' hidden lg:block absolute top-0 right-0 mr-16 h-full overflow-auto bg-white px-4'>
                                <div className='text-md flex bg-white mb-4'>
                                    <div className={`px-2 py-1 font-bold cursor-pointer mr-8 ${city === 'moscow' ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => {setCenter(moscow); setCity('moscow')}}>Москва</div>
                                    <div className={`px-2 py-1 font-bold cursor-pointer ${city === 'saint' ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => {setCenter(saintp); setCity('saint')}}>Санкт-Петербург</div>
                                </div>
                                <Addreses />
                            </div>


                        </div>
                    </YMaps>
                </div>
                <div style={{ height: '350px' }} className='overflow-auto bg-white px-4 mt-4 block lg:hidden'>
                    <div className='text-md flex bg-white mb-4 lg:hidden'>
                        <div className={`px-2 py-1 font-bold cursor-pointer mr-8 ${city === 'moscow' ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => {setCenter(moscow); setCity('moscow')}}>Москва</div>
                        <div className={`px-2 py-1 font-bold cursor-pointer ${city === 'saint' ? 'bg-gold-200 rounded-md' : ''}`} onClick={() => {setCenter(saintp); setCity('saint')}}>Санкт-Петербург</div>
                    </div>
                    <AddresesM />
                </div>
            </div>

        )
    }

    return (
        <div >
            <div className='container mx-auto'>
                <div className='mx-2'>
                    <Benefits />
                    <Philosophy />
                    <Services />
                </div>

            </div>
            <div className=''>
                <div >
                    <Portfolio />
                </div>
                <div className='mx-2'>
                    <Brands />
                </div>
            </div>
            <YandexMapComponent />
            <div className='container mx-auto'>
                <div className='mx-2'>
                    <Contacts />
                    <Gift />
                    <People />
                    <Follow />
                    <School />
                </div>
            </div>
            <div className='mx-2'>
                <Accordion />
            </div>
            <div className='container mx-auto'>
                <div className='mx-2'>
                    <ContactSchool />
                    <div className='font-extrabold text-xl flex md:flex-row flex-col items-start'>
                        <Button additional='mr-8'>заказать звонок</Button>
                        <a className='text-xl underline' style={{ textDecorationColor: '#C2AB6D' }}  href="tel:+7 921 366 50 12">+7 921 366 50 12</a>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default Main;