import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import mdx from '../../media/img/md-x.png'

const Modal = (props) => {
    const { route } = props
    const [status, setStatus] = useState();
    const location = useLocation()
    const history = useHistory()

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus("SUCCESS");
            } else {
                setStatus("ERROR");
            }
        };
        xhr.send(data);
    }

    if (location.hash === route) {
        return (
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.4', zIndex: '99999' }} className='w-full h-full  top-0 fixed' >
                <div className='container mx-auto'>
                    <div className='p-4 w-full sm:w-3/4 md:w-1/2 lg:w-1/3' style={{ position: 'fixed', top: '50vh', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <div className='bg-white p-4 w-full h-full relative'>
                            <button className='absolute ' style={{ right: '8px' }} onClick={() => history.push('/')}>
                                <img src={mdx} alt="close button" />
                            </button>
                            <form
                                onSubmit={submitForm}
                                action="https://formspree.io/f/xqkgqkvz"
                                method="POST"
                            >
                                <input className=' border-gold-500 w-10/12 p-1 md:p-2 rounded-full border-2 block mb-10 bg-transparent text-lg md:text-2xl focus:outline-none' type="text" placeholder='Имя' name="phone"></input>
                                <input className=' border-gold-500 w-10/12 p-1 md:p-2 rounded-full border-2 block mb-10 bg-transparent text-lg md:text-2xl focus:outline-none' type="text" placeholder='+79991122333' name="phone"></input>
                                <input className=' block my-2 rounded-full border-gold-500 border-2 font-bold hover:bg-gold-500 transition-colors duration-100 pt-1 pb-2 px-2 text-xl bg-transparent' type="submit" value="Заказать звонок"></input>
                                {status === "SUCCESS" && <p className='font-bold'>С вами скоро свяжутся.</p>}
                                {status === "ERROR" && <p>Произошла ошибка.</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null
    }
};

export default Modal;