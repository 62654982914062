import React from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Header from './components/Header'
import Main from './components/Main'
import Modal from './components/Modal'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Main />
        <Modal route='#call' />
      </div>
    </Router>
  );
}

export default App;
