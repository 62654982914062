import React from 'react';
import { useHistory } from 'react-router-dom'
// const history = useHistory()
// onClick={() => history.push('#call')

const Button = (props) => {
    const history = useHistory()

    const { additional, children, size, isCall } = props;
    const styles = 'inline-block my-2 rounded-full border-gold-500 border-2 font-bold hover:bg-gold-500 transition-colors duration-100'
    if (isCall) {
        return (
            <a href='https://n82583.yclients.com/group:76752?gcid=857743383.1580235661' className={`${styles} ${additional}  ${size==='big' ? 'md:py-2 md:px-4 md:text-2xl md:my-4 pt-1 pb-2 px-2 text-xl' : 'pt-1 pb-2 px-2 text-xl'}`}>
                {children}
            </a>
        );
    } else {
        return (
            <button onClick={() => history.push('#call')} className={`${styles} ${additional}  ${size==='big' ? 'md:py-2 md:px-4 md:text-2xl md:my-4 pt-1 pb-2 px-2 text-xl' : 'pt-1 pb-2 px-2 text-xl'}`}>
                {children}
            </button>
        );
    }
};

export default Button;