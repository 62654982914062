import React, { useState, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import FadeIn from 'react-fade-in';

// Media
import video from '../../media/video/vid_bcg.mp4'
import logo from '../../media/svg/Logo.svg'
import pause from '../../media/svg/pause.svg'
import vk from '../../media/svg/vk.svg'
import yt from '../../media/svg/yt.svg'
import inst from '../../media/svg/inst.svg'
import burger from '../../media/svg/burger.svg'
import gplay from '../../media/img/gplay.png'
import appstore from '../../media/img/appstore.png'
// Components
import Button from '../Button'

const Header = () => {
    const [isMenu, setMenu] = useState(false)
    const [isVideo, setVideo] = useState(true)
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        if (isVideo) {
            vidRef.current.pause();
            setVideo(false)
        } else {
            vidRef.current.play();
            setVideo(true)
        }
    }
    return (
        <div className='h-screen w-full '>
            <video ref={vidRef} autoPlay muted loop className="videoBg absolute">
                Your browser does not support HTML5 video.
                <source type="video/mp4" src={video}></source>
            </video>
            <div>
                <div className='flex flex-wrap justify-between md:mx-16 mx-4 md:mt-16 mt-4'>
                    <img className='w-1/2 md:w-auto' src={logo} alt="CHAIN" />
                    <div className={`w-1/3 md:w-auto md:ml-0 mt-6 md:mt-0 self-center burger ${isMenu ? 'burger--open' : ''}`} >
                        <a href="https://play.google.com/store/apps/details?id=com.yclients.mobile.g76752" target="_blank" rel="noopener noreferrer">
                            <img className='inline mr-4 mb-4 md:mb-0' src={gplay} alt="Google Play" />
                        </a>
                        <a href="https://apps.apple.com/ru/app/chain-barbershop/id1380790459" target="_blank" rel="noopener noreferrer">
                            <img className='inline' src={appstore} alt="App Store" />
                        </a>
                    </div>
                </div>
                <div className='container mx-auto md:px-16 px-4 flex md:flex-col justify-between'>
                    <section className='mt-8 w-12'>
                        <a  href="https://www.instagram.com/chain_barbershop/" target="_blank" rel="noopener noreferrer"><img className='mt-2' src={inst} alt="instagram" /></a>
                        <a  href="https://www.youtube.com/channel/UC7ja0W-aoZMOzMKNIjvB5RQ" target="_blank" rel="noopener noreferrer"><img className='mt-2' src={yt} alt="youtube" /></a>
                        <a  href="https://vk.com/chain_barbershop" target="_blank" rel="noopener noreferrer"><img className='mt-2' src={vk} alt="vkontakte" /></a>
                    </section>
                    <div className={`hidden md:block ml-auto mt-8 md:mt-10 px-20 py-8 md:py-32 burger pulse ${isMenu ? 'opacity-0' : ''}`} onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)}><img src={burger} alt="menu" /></div>

                    <section className='bottom absolute bottom-0'>
                        <FadeIn delay={1000} transitionDuration={1000}>
                            <div>
                                <div style={{ lineHeight: '52px', margin: '0 0 2rem 0' }} className="text-3xl md:text-5xl font-bold text-white">
                                    Сеть мужских <br /> парикмахерских
                                </div>
                                <div className="text-xl md:text-2xl font-bold text-white">
                                    « Be your brand everyday »
                                </div>
                                <div className='md:my-12 my-4 flex justify-between'>
                                    <div>
                                        <Button isCall isFilled size='big' additional='md:pb-3 pb-1 text-white' >записаться онлайн</Button>
                                    </div>

                                    <button onClick={() => handlePlayVideo()} className={`md:block hidden rounded-full ml-24 border-gold-500 border-2 font-bold  transition-colors duration-100 w-16 h-16 py-2 px-4 text-2xl  my-4`}>
                                        <img className='mx-auto' src={pause} alt="pause" />
                                    </button>
                                </div>
                            </div>
                        </FadeIn>
                    </section>
                </div>
            </div>

            <div className={`navBar md:w-1/3 lg:w-1/4 ${isMenu ? 'navBar--open' : ''}`} onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)} onClick={() => setMenu(false)}>
                <ul style={{ margin: '0 4rem', transform: ' translate(0, 60%)' }} className='text-3xl font-extrabold text-white center'>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#history">философия</Link>
                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#services">услуги</Link>
                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#cosmetics">косметика</Link>
                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#team">команда</Link>
                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#map">наши адреса</Link>
                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#social">соц. сети</Link>

                    </li>
                    <li>
                        <Link className='hover:text-gold-500' smooth to="#school">школа</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Header;